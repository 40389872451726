"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lifiApi = void 0;
const sdk_1 = require("@lifi/sdk");
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const unchained_client_1 = require("@shapeshiftoss/unchained-client");
const utils_1 = require("@shapeshiftoss/utils");
const build_1 = require("@sniptt/monads/build");
const types_1 = require("../../types");
const utils_2 = require("../../utils");
const getTradeQuote_1 = require("./getTradeQuote/getTradeQuote");
const configureLiFi_1 = require("./utils/configureLiFi");
const getLifiChainMap_1 = require("./utils/getLifiChainMap");
const tradeQuoteMetadata = new Map();
// cached metadata - would need persistent cache with expiry if moved server-side
let lifiChainMapPromise;
exports.lifiApi = {
    // TODO: this isn't a pure swapper method, see https://github.com/shapeshift/web/pull/5519
    // We currently need to pass assetsById to avoid instantiating AssetService in web
    // but will need to remove this second arg once this lives outside of web, to keep things pure and swappery
    getTradeQuote: async (input, deps) => {
        if (input.sellAmountIncludingProtocolFeesCryptoBaseUnit === '0') {
            return (0, build_1.Err)((0, utils_2.makeSwapErrorRight)({
                message: 'sell amount too low',
                code: types_1.TradeQuoteError.SellAmountBelowMinimum,
            }));
        }
        if (lifiChainMapPromise === undefined)
            lifiChainMapPromise = (0, getLifiChainMap_1.getLifiChainMap)();
        const lifiChainMap = await lifiChainMapPromise;
        const tradeQuoteResult = await (0, getTradeQuote_1.getTradeQuote)(input, deps, lifiChainMap);
        return tradeQuoteResult.map(quote => quote.map(({ selectedLifiRoute, ...tradeQuote }) => {
            // TODO: quotes below the minimum aren't valid and should not be processed as such
            // selectedLifiRoute will be missing for quotes below the minimum
            if (!selectedLifiRoute)
                throw Error('missing selectedLifiRoute');
            const id = selectedLifiRoute.id;
            // store the lifi quote metadata for transaction building later
            tradeQuoteMetadata.set(id, selectedLifiRoute);
            return tradeQuote;
        }));
    },
    getUnsignedEvmTransaction: async ({ chainId, from, stepIndex, tradeQuote, supportsEIP1559, assertGetEvmChainAdapter, }) => {
        (0, configureLiFi_1.configureLiFi)();
        const lifiRoute = tradeQuoteMetadata.get(tradeQuote.id);
        if (!lifiRoute)
            throw Error(`missing trade quote metadata for quoteId ${tradeQuote.id}`);
        const lifiStep = lifiRoute.steps[stepIndex];
        const { transactionRequest } = await (0, sdk_1.getStepTransaction)(lifiStep);
        if (!transactionRequest) {
            throw Error('undefined transactionRequest');
        }
        const { to, value, data, gasLimit } = transactionRequest;
        // checking values individually to keep type checker happy
        if (to === undefined || value === undefined || data === undefined || gasLimit === undefined) {
            const undefinedRequiredValues = [to, value, data, gasLimit].filter(value => value === undefined);
            throw Error('undefined required values in transactionRequest', {
                cause: {
                    undefinedRequiredValues,
                },
            });
        }
        const feeData = await chain_adapters_1.evm.getFees({
            adapter: assertGetEvmChainAdapter(chainId),
            data: data.toString(),
            to,
            value: (0, utils_1.bn)(value.toString()).toString(),
            from,
            supportsEIP1559,
        });
        return {
            to,
            from,
            value: value.toString(),
            data: data.toString(),
            chainId: Number((0, caip_1.fromChainId)(chainId).chainReference),
            ...{ ...feeData, gasLimit: gasLimit.toString() },
        };
    },
    checkTradeStatus: async ({ quoteId, txHash, stepIndex, chainId, accountId, fetchIsSmartContractAddressQuery, assertGetEvmChainAdapter, }) => {
        const lifiRoute = tradeQuoteMetadata.get(quoteId);
        if (!lifiRoute)
            throw Error(`missing trade quote metadata for quoteId ${quoteId}`);
        // getMixPanel()?.track(MixPanelEvent.SwapperApiRequest, {
        //   swapper: SwapperName.LIFI,
        //   method: 'get',
        //   // Note, this may change if the Li.Fi SDK changes
        //   url: 'https://li.quest/v1/status',
        // })
        const { action: { fromChainId, toChainId }, tool, } = lifiRoute.steps[stepIndex];
        const maybeSafeTransactionStatus = await (0, utils_2.checkSafeTransactionStatus)({
            txHash,
            chainId,
            assertGetEvmChainAdapter,
            accountId,
            fetchIsSmartContractAddressQuery,
        });
        if (maybeSafeTransactionStatus) {
            // return any safe transaction status that has not yet executed on chain (no buyTxHash)
            if (!maybeSafeTransactionStatus.buyTxHash)
                return maybeSafeTransactionStatus;
            // The safe buyTxHash is the on chain transaction hash (not the safe transaction hash).
            // Mutate txHash and continue with regular status check flow.
            txHash = maybeSafeTransactionStatus.buyTxHash;
        }
        // don't use lifi sdk here because all status responses are cached, negating the usefulness of polling
        // i.e don't do `await getLifi().getStatus(getStatusRequest)`
        const url = new URL('https://li.quest/v1/status');
        const getStatusRequestParams = {
            txHash,
            bridge: tool,
            fromChain: fromChainId.toString(),
            toChain: toChainId.toString(),
        };
        url.search = new URLSearchParams(getStatusRequestParams).toString();
        const response = await fetch(url, { cache: 'no-store' }); // don't cache!
        if (!response.ok)
            return (0, utils_2.createDefaultStatusResponse)();
        const statusResponse = await response.json();
        const status = (() => {
            switch (statusResponse.status) {
                case 'DONE':
                    return unchained_client_1.TxStatus.Confirmed;
                case 'PENDING':
                    return unchained_client_1.TxStatus.Pending;
                case 'FAILED':
                    return unchained_client_1.TxStatus.Failed;
                default:
                    return unchained_client_1.TxStatus.Unknown;
            }
        })();
        return {
            status,
            buyTxHash: statusResponse.receiving?.txHash,
            message: statusResponse.substatusMessage,
        };
    },
};
