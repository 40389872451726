"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getOneInchTokenAddress = exports.assertValidTrade = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const build_1 = require("@sniptt/monads/build");
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const constants_1 = require("./constants");
const types_2 = require("./types");
const assertValidTrade = ({ buyAsset, sellAsset, }) => {
    if (!types_2.oneInchSupportedChainIds.includes(sellAsset.chainId) ||
        !types_2.oneInchSupportedChainIds.includes(buyAsset.chainId)) {
        return (0, build_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `[OneInch: assertValidTrade] - unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { buyAsset, sellAsset },
        }));
    }
    if (sellAsset.chainId !== buyAsset.chainId) {
        return (0, build_1.Err)((0, utils_1.makeSwapErrorRight)({
            message: `[OneInch: assertValidTrade] - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    return (0, build_1.Ok)(true);
};
exports.assertValidTrade = assertValidTrade;
const getOneInchTokenAddress = (asset) => {
    return (0, helpers_1.isNativeEvmAsset)(asset.assetId)
        ? constants_1.ONE_INCH_NATIVE_ASSET_ADDRESS
        : (0, caip_1.fromAssetId)(asset.assetId).assetReference;
};
exports.getOneInchTokenAddress = getOneInchTokenAddress;
