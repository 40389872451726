"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchFromZrxPermit2 = exports.fetchFromZrx = void 0;
const contracts_1 = require("@shapeshiftoss/contracts");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const helpers_1 = require("../../utils/helpers/helpers");
const constants_2 = require("./constants");
const helpers_2 = require("./helpers/helpers");
const zrxService_1 = require("./zrxService");
const fetchFromZrx = async ({ priceOrQuote, buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, receiveAddress, affiliateBps, slippageTolerancePercentageDecimal, zrxBaseUrl, }) => {
    const baseUrl = (0, helpers_2.baseUrlFromChainId)(zrxBaseUrl, buyAsset.chainId);
    const zrxService = (0, zrxService_1.zrxServiceFactory)({ baseUrl });
    const maybeTreasuryAddress = (() => {
        try {
            return (0, helpers_1.getTreasuryAddressFromChainId)(buyAsset.chainId);
        }
        catch (err) { }
    })();
    // https://docs.0x.org/0x-swap-api/api-references/get-swap-v1-quote
    const maybeZrxPriceResponse = await zrxService.get(`/swap/v1/${priceOrQuote}`, {
        params: {
            enableSlippageProtection: true,
            buyToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            sellToken: (0, helpers_2.assetIdToZrxToken)(sellAsset.assetId),
            sellAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            takerAddress: receiveAddress,
            affiliateAddress: constants_2.AFFILIATE_ADDRESS,
            skipValidation: priceOrQuote === 'price',
            slippagePercentage: slippageTolerancePercentageDecimal ??
                (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx),
            ...(maybeTreasuryAddress && {
                feeRecipient: maybeTreasuryAddress,
                feeRecipientTradeSurplus: maybeTreasuryAddress,
                buyTokenPercentageFee: (0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps).toNumber(),
            }),
        },
    });
    if (maybeZrxPriceResponse.isErr()) {
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    }
    return (0, monads_1.Ok)(maybeZrxPriceResponse.unwrap().data);
};
exports.fetchFromZrx = fetchFromZrx;
const fetchFromZrxPermit2 = async ({ priceOrQuote, buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, receiveAddress, affiliateBps, slippageTolerancePercentageDecimal, zrxBaseUrl, }) => {
    const zrxService = (0, zrxService_1.zrxServiceFactory)({ baseUrl: zrxBaseUrl });
    const maybeTreasuryAddress = (() => {
        try {
            return (0, helpers_1.getTreasuryAddressFromChainId)(buyAsset.chainId);
        }
        catch (err) { }
    })();
    // https://0x.org/docs/api#tag/Swap/operation/swap::permit2::getPrice
    // https://0x.org/docs/api#tag/Swap/operation/swap::permit2::getQuote
    const maybeZrxPriceResponse = await zrxService.get(`/swap/permit2/${priceOrQuote}`, {
        params: {
            chainId: contracts_1.viemNetworkIdByChainId[sellAsset.chainId],
            buyToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            sellToken: (0, helpers_2.assetIdToZrxToken)(sellAsset.assetId),
            sellAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            taker: receiveAddress,
            swapFeeBps: parseInt(affiliateBps),
            swapFeeToken: (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId),
            slippageBps: (0, utils_1.convertDecimalPercentageToBasisPoints)(slippageTolerancePercentageDecimal).toNumber(),
            swapFeeRecipient: maybeTreasuryAddress,
            feeRecipientTradeSurplus: maybeTreasuryAddress, // Where trade surplus is sent
        },
    });
    if (maybeZrxPriceResponse.isErr()) {
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    }
    return (0, monads_1.Ok)(maybeZrxPriceResponse.unwrap().data);
};
exports.fetchFromZrxPermit2 = fetchFromZrxPermit2;
