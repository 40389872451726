"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTradeQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const getApprovalAddress_1 = require("../getApprovalAddress/getApprovalAddress");
const helpers_2 = require("../utils/helpers");
const oneInchService_1 = require("../utils/oneInchService");
async function getTradeQuote(input, deps) {
    const { chainId, sellAsset, buyAsset, accountNumber, affiliateBps, potentialAffiliateBps, supportsEIP1559, receiveAddress, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const apiUrl = deps.config.REACT_APP_ONE_INCH_API_URL;
    const assertion = (0, helpers_2.assertValidTrade)({ buyAsset, sellAsset });
    if (assertion.isErr())
        return (0, monads_1.Err)(assertion.unwrapErr());
    const maybeTreasuryAddress = (() => {
        try {
            return (0, helpers_1.getTreasuryAddressFromChainId)(buyAsset.chainId);
        }
        catch (err) { }
    })();
    const buyTokenPercentageFee = (0, utils_1.convertBasisPointsToPercentage)(affiliateBps).toNumber();
    const params = {
        fromTokenAddress: (0, helpers_2.getOneInchTokenAddress)(sellAsset),
        toTokenAddress: (0, helpers_2.getOneInchTokenAddress)(buyAsset),
        receiver: receiveAddress,
        amount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
        ...(maybeTreasuryAddress && {
            fee: buyTokenPercentageFee,
        }),
    };
    const { chainReference } = (0, caip_1.fromChainId)(chainId);
    const maybeQuoteResponse = await oneInchService_1.oneInchService.get(`${apiUrl}/${chainReference}/quote`, { params });
    if (maybeQuoteResponse.isErr())
        return (0, monads_1.Err)(maybeQuoteResponse.unwrapErr());
    const { data: quote } = maybeQuoteResponse.unwrap();
    const { toTokenAmount: buyAmountAfterFeesCryptoBaseUnit } = quote;
    const buyAmountBeforeFeesCryptoBaseUnit = (0, utils_1.addBasisPointAmount)(buyAmountAfterFeesCryptoBaseUnit, affiliateBps);
    const rate = (0, utils_2.getRate)({
        sellAsset,
        buyAsset,
        sellAmountCryptoBaseUnit: quote.fromTokenAmount,
        buyAmountCryptoBaseUnit: quote.toTokenAmount,
    });
    const maybeAllowanceContract = await (0, getApprovalAddress_1.getApprovalAddress)(apiUrl, chainId);
    if (maybeAllowanceContract.isErr())
        return (0, monads_1.Err)(maybeAllowanceContract.unwrapErr());
    const allowanceContract = maybeAllowanceContract.unwrap();
    try {
        // assert get is allowed because we chain chainId is an EVM chainId above in assertValidTrade
        const adapter = deps.assertGetEvmChainAdapter(chainId);
        const { average } = await adapter.getGasFeeData();
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559,
            gasLimit: quote.estimatedGas,
            l1GasLimit: '0', // TODO: support l1 gas limit for accurate optimism estimations
        });
        return (0, monads_1.Ok)({
            id: (0, uuid_1.v4)(),
            receiveAddress,
            affiliateBps,
            potentialAffiliateBps,
            rate,
            // slippage is a pass-thru for this swapper because it's actually inputted to 1inch when building the tx
            slippageTolerancePercentageDecimal: input.slippageTolerancePercentageDecimal ??
                (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.OneInch),
            steps: [
                {
                    estimatedExecutionTimeMs: undefined,
                    allowanceContract,
                    rate,
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    feeData: {
                        protocolFees: {},
                        networkFeeCryptoBaseUnit,
                    },
                    source: types_1.SwapperName.OneInch,
                },
            ],
        });
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: '[OneInch: tradeQuote] - failed to get fee data',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
exports.getTradeQuote = getTradeQuote;
