"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneInchSwapper = void 0;
const utils_1 = require("../../utils");
const filterAssetIdsBySellable_1 = require("../utils/filterAssetIdsBySellable/filterAssetIdsBySellable");
const filterBuyAssetsBySellAssetId_1 = require("../utils/filterBuyAssetsBySellAssetId/filterBuyAssetsBySellAssetId");
exports.oneInchSwapper = {
    executeEvmTransaction: utils_1.executeEvmTransaction,
    filterAssetIdsBySellable: (assets) => {
        return Promise.resolve((0, filterAssetIdsBySellable_1.filterEvmAssetIdsBySellable)(assets).map(asset => asset.assetId));
    },
    filterBuyAssetsBySellAssetId: (input) => {
        return Promise.resolve((0, filterBuyAssetsBySellAssetId_1.filterSameChainEvmBuyAssetsBySellAssetId)(input).map(asset => asset.assetId));
    },
};
