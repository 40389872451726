"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CowNetwork = exports.CoWSwapBuyTokenDestination = exports.CoWSwapSellTokenSource = exports.CoWSwapSigningScheme = exports.CoWSwapOrderKind = void 0;
// Most likely non-exhaustive, see https://github.com/cowprotocol/contracts/blob/aaffdc55b2a13738b7c32de96f487d3eb5b4f8c6/src/ts/api.ts#L110
// But we only handle SellAmountDoesNotCoverFee for now so that's fine. Add other errors here as needed.
var CowSwapQuoteErrorType;
(function (CowSwapQuoteErrorType) {
    CowSwapQuoteErrorType["SellAmountDoesNotCoverFee"] = "SellAmountDoesNotCoverFee";
    CowSwapQuoteErrorType["NoLiquidity"] = "NoLiquidity";
})(CowSwapQuoteErrorType || (CowSwapQuoteErrorType = {}));
var CoWSwapOrderKind;
(function (CoWSwapOrderKind) {
    CoWSwapOrderKind["Buy"] = "buy";
    CoWSwapOrderKind["Sell"] = "sell";
})(CoWSwapOrderKind || (exports.CoWSwapOrderKind = CoWSwapOrderKind = {}));
var CoWSwapSigningScheme;
(function (CoWSwapSigningScheme) {
    CoWSwapSigningScheme["EIP712"] = "eip712";
    CoWSwapSigningScheme["EIP1271"] = "eip1271";
    CoWSwapSigningScheme["EthSign"] = "ethsign";
    CoWSwapSigningScheme["PreSign"] = "presign";
})(CoWSwapSigningScheme || (exports.CoWSwapSigningScheme = CoWSwapSigningScheme = {}));
var CoWSwapSellTokenSource;
(function (CoWSwapSellTokenSource) {
    CoWSwapSellTokenSource["ERC20"] = "erc20";
    CoWSwapSellTokenSource["External"] = "external";
    CoWSwapSellTokenSource["Internal"] = "internal";
})(CoWSwapSellTokenSource || (exports.CoWSwapSellTokenSource = CoWSwapSellTokenSource = {}));
var CoWSwapBuyTokenDestination;
(function (CoWSwapBuyTokenDestination) {
    CoWSwapBuyTokenDestination["ERC20"] = "erc20";
    CoWSwapBuyTokenDestination["Internal"] = "internal";
})(CoWSwapBuyTokenDestination || (exports.CoWSwapBuyTokenDestination = CoWSwapBuyTokenDestination = {}));
var CowNetwork;
(function (CowNetwork) {
    CowNetwork["Mainnet"] = "mainnet";
    CowNetwork["Xdai"] = "xdai";
    CowNetwork["ArbitrumOne"] = "arbitrum_one";
})(CowNetwork || (exports.CowNetwork = CowNetwork = {}));
