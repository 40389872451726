"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneInchService = void 0;
const types_1 = require("../../../types");
const utils_1 = require("../../../utils");
const maxAge = 5 * 1000; // 5 seconds
// Add cached URLs here if some are cachable
const cachedUrls = [];
const axiosConfig = {
    timeout: 10000,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};
const oneInchServiceBase = (0, utils_1.createCache)(maxAge, cachedUrls, axiosConfig);
exports.oneInchService = (0, utils_1.makeSwapperAxiosServiceMonadic)(oneInchServiceBase, types_1.SwapperName.OneInch);
