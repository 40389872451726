"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchOneInchSwap = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const utils_1 = require("@shapeshiftoss/utils");
const helpers_1 = require("../../utils/helpers/helpers");
const helpers_2 = require("./helpers");
const oneInchService_1 = require("./oneInchService");
const fetchOneInchSwap = async ({ affiliateBps, buyAsset, receiveAddress, sellAmountIncludingProtocolFeesCryptoBaseUnit, sellAsset, maximumSlippageDecimalPercentage, sendAddress, config, }) => {
    const apiUrl = config.REACT_APP_ONE_INCH_API_URL;
    // The maximum acceptable slippage percentage for the swap (e.g., 1 for 1%)
    // Note, we internally represent slippage as a decimal across the app (e.g., 0.01 for 1%)
    // so we need to multiply it by 100 when calling 1inch swap endpoint
    const maximumSlippagePercentage = (0, utils_1.bnOrZero)(maximumSlippageDecimalPercentage).times(100).toNumber();
    const maybeTreasuryAddress = (() => {
        try {
            return (0, helpers_1.getTreasuryAddressFromChainId)(buyAsset.chainId);
        }
        catch (err) { }
    })();
    const buyTokenPercentageFee = (0, utils_1.convertBasisPointsToPercentage)(affiliateBps).toNumber();
    const params = {
        fromTokenAddress: (0, helpers_2.getOneInchTokenAddress)(sellAsset),
        toTokenAddress: (0, helpers_2.getOneInchTokenAddress)(buyAsset),
        receiver: receiveAddress,
        fromAddress: sendAddress,
        amount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
        slippage: maximumSlippagePercentage,
        allowPartialFill: false,
        disableEstimate: false,
        ...(maybeTreasuryAddress && {
            referrerAddress: maybeTreasuryAddress,
            fee: buyTokenPercentageFee,
        }),
    };
    const { chainReference } = (0, caip_1.fromChainId)(sellAsset.chainId);
    const maybeSwapResponse = await oneInchService_1.oneInchService.get(`${apiUrl}/${chainReference}/swap`, { params });
    if (maybeSwapResponse.isErr())
        throw maybeSwapResponse.unwrapErr();
    const { data: swap } = maybeSwapResponse.unwrap();
    return swap;
};
exports.fetchOneInchSwap = fetchOneInchSwap;
