"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApprovalAddress = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const monads_1 = require("@sniptt/monads");
const oneInchService_1 = require("../utils/oneInchService");
async function getApprovalAddress(apiUrl, chainId) {
    const { chainReference } = (0, caip_1.fromChainId)(chainId);
    const maybeSpenderResponse = await oneInchService_1.oneInchService.get(`${apiUrl}/${chainReference}/approve/spender`);
    return maybeSpenderResponse.andThen(spenderResponse => (0, monads_1.Ok)(spenderResponse.data.address));
}
exports.getApprovalAddress = getApprovalAddress;
