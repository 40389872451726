"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getZrxTradeQuote = void 0;
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const contracts_1 = require("@shapeshiftoss/contracts");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const uuid_1 = require("uuid");
const constants_1 = require("../../../constants");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const fetchFromZrx_1 = require("../utils/fetchFromZrx");
const helpers_2 = require("../utils/helpers/helpers");
function getZrxTradeQuote(input, assertGetEvmChainAdapter, isPermit2Enabled, assetsById, zrxBaseUrl) {
    if (!isPermit2Enabled)
        return _getZrxTradeQuote(input, assertGetEvmChainAdapter, zrxBaseUrl);
    return _getZrxPermit2TradeQuote(input, assertGetEvmChainAdapter, assetsById, zrxBaseUrl);
}
exports.getZrxTradeQuote = getZrxTradeQuote;
async function _getZrxTradeQuote(input, assertGetEvmChainAdapter, zrxBaseUrl) {
    const { sellAsset, buyAsset, accountNumber, receiveAddress, affiliateBps, potentialAffiliateBps, chainId, supportsEIP1559, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx);
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, helpers_2.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_2.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    const maybeZrxPriceResponse = await (0, fetchFromZrx_1.fetchFromZrx)({
        priceOrQuote: 'price',
        buyAsset,
        sellAsset,
        sellAmountIncludingProtocolFeesCryptoBaseUnit,
        receiveAddress,
        affiliateBps,
        slippageTolerancePercentageDecimal,
        zrxBaseUrl,
    });
    if (maybeZrxPriceResponse.isErr())
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    const zrxQuoteResponse = maybeZrxPriceResponse.unwrap();
    const { buyAmount: buyAmountAfterFeesCryptoBaseUnit, grossBuyAmount: buyAmountBeforeFeesCryptoBaseUnit, price, allowanceTarget, gas, expectedSlippage, } = zrxQuoteResponse;
    const useSellAmount = !!sellAmountIncludingProtocolFeesCryptoBaseUnit;
    const rate = useSellAmount ? price : (0, utils_1.bn)(1).div(price).toString();
    // 0x approvals are cheaper than trades, but we don't have dynamic quote data for them.
    // Instead, we use a hardcoded gasLimit estimate in place of the estimatedGas in the 0x quote response.
    try {
        const adapter = assertGetEvmChainAdapter(chainId);
        const { average } = await adapter.getGasFeeData();
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559,
            // add gas limit buffer to account for the fact we perform all of our validation on the trade quote estimations
            // which are inaccurate and not what we use for the tx to broadcast
            gasLimit: (0, utils_1.bnOrZero)(gas).times(1.2).toFixed(),
        });
        return (0, monads_1.Ok)({
            id: (0, uuid_1.v4)(),
            receiveAddress,
            potentialAffiliateBps,
            affiliateBps,
            // Slippage protection is only provided for specific pairs.
            // If slippage protection is not provided, assume a no slippage limit.
            // If slippage protection is provided, return the limit instead of the estimated slippage.
            // https://0x.org/docs/0x-swap-api/api-references/get-swap-v1-quote
            slippageTolerancePercentageDecimal: expectedSlippage
                ? slippageTolerancePercentageDecimal
                : undefined,
            rate,
            steps: [
                {
                    estimatedExecutionTimeMs: undefined,
                    allowanceContract: allowanceTarget,
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    rate,
                    feeData: {
                        protocolFees: {},
                        networkFeeCryptoBaseUnit, // L1 fee added inside of evm.calcNetworkFeeCryptoBaseUnit
                    },
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    source: types_1.SwapperName.Zrx,
                },
            ],
        });
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'failed to get fee data',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
async function _getZrxPermit2TradeQuote(input, assertGetEvmChainAdapter, assetsById, zrxBaseUrl) {
    const { sellAsset, buyAsset, accountNumber, receiveAddress, affiliateBps, potentialAffiliateBps, chainId, supportsEIP1559, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const slippageTolerancePercentageDecimal = input.slippageTolerancePercentageDecimal ??
        (0, constants_1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Zrx);
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, helpers_2.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_2.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    const maybeZrxPriceResponse = await (0, fetchFromZrx_1.fetchFromZrxPermit2)({
        priceOrQuote: 'quote',
        buyAsset,
        sellAsset,
        sellAmountIncludingProtocolFeesCryptoBaseUnit,
        receiveAddress,
        affiliateBps,
        slippageTolerancePercentageDecimal,
        zrxBaseUrl,
    });
    if (maybeZrxPriceResponse.isErr())
        return (0, monads_1.Err)(maybeZrxPriceResponse.unwrapErr());
    const zrxQuoteResponse = maybeZrxPriceResponse.unwrap();
    const { sellAmount, buyAmount, fees, permit2: quotePermit2, transaction } = zrxQuoteResponse;
    const permit2Eip712 = quotePermit2?.eip712 ?? null;
    if (!(0, helpers_1.isNativeEvmAsset)(sellAsset.assetId) && !permit2Eip712) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Missing required Permit2 metadata from 0x response',
            code: types_1.TradeQuoteError.InvalidResponse,
            details: { transaction, permit2Eip712 },
        }));
    }
    if (!transaction) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'Missing required transaction metadata from 0x response',
            code: types_1.TradeQuoteError.InvalidResponse,
            details: { transaction, permit2Eip712 },
        }));
    }
    const transactionMetadata = {
        to: transaction.to,
        data: transaction.data,
        gasPrice: transaction.gasPrice ? BigInt(transaction.gasPrice) : undefined,
        gas: transaction.gas ? BigInt(transaction.gas) : undefined,
        value: BigInt(transaction.value),
    };
    // for the rate to be valid, both amounts must be converted to the same precision
    const rate = (0, utils_1.convertPrecision)({
        value: buyAmount,
        inputExponent: buyAsset.precision,
        outputExponent: sellAsset.precision,
    })
        .dividedBy((0, utils_1.bn)(sellAmount))
        .toFixed();
    // The integrator fee is set to the buy asset in fetchFromZrxPermit2, but paranoia
    if (fees.integratorFee !== null &&
        fees.integratorFee.token !== (0, helpers_2.assetIdToZrxToken)(buyAsset.assetId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `Unhandled integrator fee asset '${fees.integratorFee.token}'`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    // We can safely add the integrator fee now we know its the correct asset.
    const integratorFeeCryptoBaseUnit = fees.integratorFee?.amount ?? '0';
    const buyAmountBeforeFeesCryptoBaseUnit = (0, utils_1.bn)(buyAmount)
        .plus(integratorFeeCryptoBaseUnit)
        .toFixed();
    try {
        const adapter = assertGetEvmChainAdapter(chainId);
        const { average } = await adapter.getGasFeeData();
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559,
            // add gas limit buffer to account for the fact we perform all of our validation on the trade quote estimations
            // which are inaccurate and not what we use for the tx to broadcast
            gasLimit: (0, utils_1.bnOrZero)(transaction.gas).times(1.2).toFixed(),
        });
        const protocolFees = (() => {
            if (!fees.zeroExFee)
                return {};
            const assetId = (0, helpers_2.zrxTokenToAssetId)(fees.zeroExFee.token, sellAsset.chainId);
            return {
                [assetId]: {
                    requiresBalance: false,
                    amountCryptoBaseUnit: fees.zeroExFee.amount,
                    asset: assetsById[assetId],
                },
            };
        })();
        return (0, monads_1.Ok)({
            id: (0, uuid_1.v4)(),
            receiveAddress,
            potentialAffiliateBps,
            affiliateBps,
            // Slippage protection is always enabled for 0x api v2 unlike api v1 which was only supported on specific pairs.
            slippageTolerancePercentageDecimal,
            rate,
            steps: [
                {
                    estimatedExecutionTimeMs: undefined,
                    allowanceContract: (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId) ? undefined : contracts_1.PERMIT2_CONTRACT,
                    buyAsset,
                    sellAsset,
                    accountNumber,
                    rate,
                    feeData: {
                        protocolFees,
                        networkFeeCryptoBaseUnit, // L1 fee added inside of evm.calcNetworkFeeCryptoBaseUnit
                    },
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit: buyAmount,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    source: types_1.SwapperName.Zrx,
                    permit2Eip712: permit2Eip712,
                    transactionMetadata,
                },
            ],
        });
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'failed to get fee data',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
