"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSupportedBuyAssetIds = exports.getSupportedSellAssetIds = exports.getTradeQuotes = void 0;
const utils_1 = require("@shapeshiftoss/utils");
const constants_1 = require("./constants");
const getTradeQuotes = async (getTradeQuoteInput, swapperName, deps) => {
    if ((0, utils_1.bnOrZero)(getTradeQuoteInput.affiliateBps).lt(0))
        return;
    if (getTradeQuoteInput.sellAmountIncludingProtocolFeesCryptoBaseUnit === '0')
        return;
    const swapper = constants_1.swappers[swapperName];
    if (swapper === undefined)
        return;
    try {
        const quote = await (0, utils_1.timeoutMonadic)(swapper.getTradeQuote(getTradeQuoteInput, deps), constants_1.QUOTE_TIMEOUT_MS, constants_1.QUOTE_TIMEOUT_ERROR);
        return {
            ...quote,
            swapperName,
        };
    }
    catch (e) {
        // This should never happen but it may - we should be using monadic error handling all the way through swapper call stack
        // in case this logs an error from a rejected promise, it means we throw somewhere and forgot to handle errors the monadic way
        console.error(e);
    }
};
exports.getTradeQuotes = getTradeQuotes;
// TODO: this isn't a pure swapper method, see https://github.com/shapeshift/web/pull/5519
// We currently need to pass assetsById to avoid instantiating AssetService in web
// but will need to remove this second arg once this lives outside of web, to keep things pure and swappery
const getSupportedSellAssetIds = async (enabledSwappers, assetsById, config) => {
    const assets = Object.values(assetsById);
    const supportedAssetIds = await Promise.all(Object.entries(constants_1.swappers)
        .filter(([swapperName, _]) => enabledSwappers[swapperName])
        .map(([_, swapper]) => swapper?.filterAssetIdsBySellable(assets, config))
        .filter(utils_1.isSome));
    return new Set(supportedAssetIds.flat());
};
exports.getSupportedSellAssetIds = getSupportedSellAssetIds;
// TODO: this isn't a pure swapper method, see https://github.com/shapeshift/web/pull/5519
// We currently need to pass assetsById to avoid instantiating AssetService in web
// but will need to remove this second arg once this lives outside of web, to keep things pure and swappery
const getSupportedBuyAssetIds = async (enabledSwappers, sellAsset, assetsById, config) => {
    const assets = Object.values(assetsById);
    const supportedAssetIds = await Promise.all(Object.entries(constants_1.swappers)
        .filter(([swapperName, _]) => enabledSwappers[swapperName])
        .map(([_, swapper]) => swapper?.filterBuyAssetsBySellAssetId({ assets, sellAsset, config }))
        .filter(utils_1.isSome));
    return new Set(supportedAssetIds.flat());
};
exports.getSupportedBuyAssetIds = getSupportedBuyAssetIds;
