"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.oneInchApi = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const uuid_1 = require("uuid");
const utils_1 = require("../../utils");
const getTradeQuote_1 = require("./getTradeQuote/getTradeQuote");
const fetchOneInchSwap_1 = require("./utils/fetchOneInchSwap");
const tradeQuoteMetadata = new Map();
exports.oneInchApi = {
    getTradeQuote: async (input, deps) => {
        const tradeQuoteResult = await (0, getTradeQuote_1.getTradeQuote)(input, deps);
        return tradeQuoteResult.map(tradeQuote => {
            const id = (0, uuid_1.v4)();
            const firstHop = (0, utils_1.getHopByIndex)(tradeQuote, 0);
            tradeQuoteMetadata.set(id, { chainId: firstHop.sellAsset.chainId });
            return [tradeQuote];
        });
    },
    getUnsignedEvmTransaction: async ({ chainId, from, slippageTolerancePercentageDecimal, stepIndex, tradeQuote, supportsEIP1559, assertGetEvmChainAdapter, config, }) => {
        const step = (0, utils_1.getHopByIndex)(tradeQuote, stepIndex);
        if (!step)
            throw new Error(`No hop found for stepIndex ${stepIndex}`);
        const { buyAsset, sellAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit } = step;
        const { receiveAddress, affiliateBps } = tradeQuote;
        const { tx: { value, to, data }, } = await (0, fetchOneInchSwap_1.fetchOneInchSwap)({
            affiliateBps,
            buyAsset,
            receiveAddress,
            sellAmountIncludingProtocolFeesCryptoBaseUnit,
            sellAsset,
            maximumSlippageDecimalPercentage: slippageTolerancePercentageDecimal,
            sendAddress: from,
            config,
        });
        const feeData = await chain_adapters_1.evm.getFees({
            adapter: assertGetEvmChainAdapter(chainId),
            data,
            to,
            value,
            from,
            supportsEIP1559,
        });
        return {
            chainId: Number((0, caip_1.fromChainId)(chainId).chainReference),
            data,
            from,
            to,
            value,
            ...feeData,
        };
    },
    checkTradeStatus: utils_1.checkEvmSwapStatus,
};
