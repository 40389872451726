"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchArbitrumBridgeSwap = void 0;
const sdk_1 = require("@arbitrum/sdk");
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const contracts_1 = require("@shapeshiftoss/contracts");
const types_1 = require("@shapeshiftoss/types");
const utils_1 = require("@shapeshiftoss/utils");
const ethers5_1 = require("ethers5");
const chains_1 = require("viem/chains");
const types_2 = require("../types");
// https://github.com/OffchainLabs/arbitrum-token-bridge/blob/d17c88ef3eef3f4ffc61a04d34d50406039f045d/packages/arb-token-bridge-ui/src/util/TokenDepositUtils.ts#L45-L51
// Use hardcoded gas estimate values
// Values set by looking at a couple of different ERC-20 deposits
const fallbackTokenGasLimit = (0, utils_1.bn)(240000);
const fetchArbitrumBridgeSwap = async ({ chainId, buyAsset, sellAmountIncludingProtocolFeesCryptoBaseUnit, sellAsset, sendAddress, receiveAddress, supportsEIP1559, assertGetEvmChainAdapter, }) => {
    const adapter = assertGetEvmChainAdapter(chainId);
    const l2Network = await (0, sdk_1.getArbitrumNetwork)(chains_1.arbitrum.id);
    const isDeposit = sellAsset.chainId === caip_1.ethChainId;
    const isEthBridge = isDeposit ? sellAsset.assetId === caip_1.ethAssetId : buyAsset.assetId === caip_1.ethAssetId;
    const bridgeType = (() => {
        if (isDeposit) {
            return isEthBridge ? types_2.BRIDGE_TYPE.ETH_DEPOSIT : types_2.BRIDGE_TYPE.ERC20_DEPOSIT;
        }
        return isEthBridge ? types_2.BRIDGE_TYPE.ETH_WITHDRAWAL : types_2.BRIDGE_TYPE.ERC20_WITHDRAWAL;
    })();
    const parentProvider = (0, contracts_1.getEthersV5Provider)(types_1.KnownChainIds.EthereumMainnet);
    const childProvider = (0, contracts_1.getEthersV5Provider)(types_1.KnownChainIds.ArbitrumMainnet);
    switch (bridgeType) {
        case types_2.BRIDGE_TYPE.ETH_DEPOSIT: {
            const bridger = new sdk_1.EthBridger(l2Network);
            const request = await bridger.getDepositToRequest({
                parentProvider,
                childProvider,
                amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                from: sendAddress ?? '',
                destinationAddress: receiveAddress,
            });
            const { networkFeeCryptoBaseUnit } = await chain_adapters_1.evm.getFees({
                adapter,
                data: request.txRequest.data.toString(),
                to: request.txRequest.to,
                value: request.txRequest.value.toString(),
                from: request.txRequest.from,
                supportsEIP1559,
            });
            return { request, allowanceContract: '0x0', networkFeeCryptoBaseUnit };
        }
        case types_2.BRIDGE_TYPE.ETH_WITHDRAWAL: {
            const bridger = new sdk_1.EthBridger(l2Network);
            const request = await bridger.getWithdrawalRequest({
                amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                from: sendAddress ?? '',
                destinationAddress: receiveAddress,
            });
            const { networkFeeCryptoBaseUnit } = await chain_adapters_1.evm.getFees({
                adapter,
                data: request.txRequest.data.toString(),
                to: request.txRequest.to,
                value: request.txRequest.value.toString(),
                from: request.txRequest.from,
                supportsEIP1559,
            });
            return { request, allowanceContract: '0x0', networkFeeCryptoBaseUnit };
        }
        case types_2.BRIDGE_TYPE.ERC20_DEPOSIT: {
            const bridger = new sdk_1.Erc20Bridger(l2Network);
            const erc20ParentAddress = (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference;
            const allowanceContract = await bridger.getParentGatewayAddress(erc20ParentAddress, parentProvider);
            const maybeRequest = await bridger
                .getDepositRequest({
                amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                parentProvider,
                childProvider,
                erc20ParentAddress,
                from: sendAddress ?? '',
                destinationAddress: receiveAddress,
                retryableGasOverrides: {
                    // https://github.com/OffchainLabs/arbitrum-token-bridge/blob/d17c88ef3eef3f4ffc61a04d34d50406039f045d/packages/arb-token-bridge-ui/src/util/TokenDepositUtils.ts#L159
                    // the gas limit may vary by about 20k due to SSTORE (zero vs nonzero)
                    // the 30% gas limit increase should cover the difference
                    gasLimit: { percentIncrease: ethers5_1.BigNumber.from(30) },
                },
            })
                .catch(e => {
                console.error('Error getting deposit request', e);
                return undefined;
            });
            const networkFeeCryptoBaseUnit = await (async () => {
                // Fallback fees
                if (!maybeRequest) {
                    const { average } = await adapter.getGasFeeData();
                    const { gasPrice, maxFeePerGas } = average;
                    // eip1559 fees
                    if (supportsEIP1559 && maxFeePerGas) {
                        return fallbackTokenGasLimit.times(maxFeePerGas).toFixed();
                    }
                    // legacy fees
                    return fallbackTokenGasLimit.times(gasPrice).toFixed();
                }
                // Actual fees
                const feeData = await chain_adapters_1.evm.getFees({
                    adapter,
                    data: maybeRequest.txRequest.data.toString(),
                    to: maybeRequest.txRequest.to,
                    value: maybeRequest.txRequest.value.toString(),
                    from: maybeRequest.txRequest.from,
                    supportsEIP1559,
                });
                return feeData.networkFeeCryptoBaseUnit;
            })();
            return { request: maybeRequest, networkFeeCryptoBaseUnit, allowanceContract };
        }
        case types_2.BRIDGE_TYPE.ERC20_WITHDRAWAL: {
            const bridger = new sdk_1.Erc20Bridger(l2Network);
            const erc20ParentAddress = (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference;
            const request = await bridger.getWithdrawalRequest({
                amount: ethers5_1.BigNumber.from(sellAmountIncludingProtocolFeesCryptoBaseUnit),
                erc20ParentAddress,
                from: sendAddress ?? '',
                destinationAddress: receiveAddress,
            });
            const { networkFeeCryptoBaseUnit } = await chain_adapters_1.evm.getFees({
                adapter,
                data: request.txRequest.data.toString(),
                to: request.txRequest.to,
                value: request.txRequest.value.toString(),
                from: request.txRequest.from,
                supportsEIP1559,
            });
            return { request, networkFeeCryptoBaseUnit, allowanceContract: '0x0' };
        }
        default:
            (0, utils_1.assertUnreachable)(bridgeType);
    }
};
exports.fetchArbitrumBridgeSwap = fetchArbitrumBridgeSwap;
