"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ONE_INCH_SUPPORTED_CHAIN_IDS = exports.ONE_INCH_NATIVE_ASSET_ADDRESS = void 0;
const types_1 = require("./types");
// Address used by 1inch for any native asset (ETH, AVAX, etc)
exports.ONE_INCH_NATIVE_ASSET_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
exports.ONE_INCH_SUPPORTED_CHAIN_IDS = {
    sell: types_1.oneInchSupportedChainIds,
    buy: types_1.oneInchSupportedChainIds,
};
