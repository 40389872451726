"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionParser = void 0;
const types_1 = require("../../types");
const utils_1 = require("../../utils");
__exportStar(require("./types"), exports);
class TransactionParser {
    constructor(args) {
        this.parsers = [];
        this.chainId = args.chainId;
        this.assetId = args.assetId;
    }
    /**
     * Register custom transaction sub parser to parse custom op return data
     *
     * _parsers should be registered from most generic first to most specific last_
     */
    registerParser(parser) {
        this.parsers.unshift(parser);
    }
    registerParsers(parsers) {
        parsers.forEach(parser => this.registerParser(parser));
    }
    async parse(tx, address) {
        const parserResult = await (async () => {
            for (const parser of this.parsers) {
                const result = await parser.parse(tx, address);
                if (result)
                    return result;
            }
        })();
        const parsedTx = {
            address,
            blockHeight: tx.blockHeight,
            blockTime: tx.timestamp,
            chainId: this.chainId,
            // all transactions from unchained are finalized with at least 1 confirmation (unused throughout web)
            confirmations: 1,
            status: this.getStatus(tx),
            trade: parserResult?.trade,
            transfers: parserResult?.transfers ?? [],
            txid: tx.txid,
        };
        // network fee
        if (tx.feePayer === address && tx.fee) {
            parsedTx.fee = { assetId: this.assetId, value: BigInt(tx.fee).toString() };
        }
        tx.nativeTransfers?.forEach(nativeTransfer => {
            const { amount, fromUserAccount, toUserAccount } = nativeTransfer;
            // send amount
            if (nativeTransfer.fromUserAccount === address) {
                parsedTx.transfers = (0, utils_1.aggregateTransfer)({
                    assetId: this.assetId,
                    from: fromUserAccount ?? '',
                    to: toUserAccount ?? '',
                    transfers: parsedTx.transfers,
                    type: types_1.TransferType.Send,
                    value: BigInt(amount).toString(),
                });
            }
            // receive amount
            if (nativeTransfer.toUserAccount === address) {
                parsedTx.transfers = (0, utils_1.aggregateTransfer)({
                    assetId: this.assetId,
                    from: fromUserAccount ?? '',
                    to: toUserAccount ?? '',
                    transfers: parsedTx.transfers,
                    type: types_1.TransferType.Receive,
                    value: BigInt(amount).toString(),
                });
            }
        });
        return parsedTx;
    }
    getStatus(tx) {
        if (tx.transactionError)
            return types_1.TxStatus.Failed;
        return types_1.TxStatus.Confirmed;
    }
}
exports.TransactionParser = TransactionParser;
