"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPortalsTradeQuote = void 0;
const caip_1 = require("@shapeshiftoss/caip");
const chain_adapters_1 = require("@shapeshiftoss/chain-adapters");
const utils_1 = require("@shapeshiftoss/utils");
const monads_1 = require("@sniptt/monads");
const viem_1 = require("viem");
const __1 = require("../../..");
const types_1 = require("../../../types");
const utils_2 = require("../../../utils");
const helpers_1 = require("../../utils/helpers/helpers");
const constants_1 = require("../constants");
const fetchPortalsTradeOrder_1 = require("../utils/fetchPortalsTradeOrder");
const helpers_2 = require("../utils/helpers");
async function getPortalsTradeQuote(input, assertGetEvmChainAdapter, swapperConfig) {
    const { sellAsset, buyAsset, sendAddress, accountNumber, affiliateBps, potentialAffiliateBps, chainId, supportsEIP1559, sellAmountIncludingProtocolFeesCryptoBaseUnit, } = input;
    const sellAssetChainId = sellAsset.chainId;
    const buyAssetChainId = buyAsset.chainId;
    if (!(0, helpers_2.isSupportedChainId)(sellAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (!(0, helpers_2.isSupportedChainId)(buyAssetChainId)) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `unsupported chainId`,
            code: types_1.TradeQuoteError.UnsupportedChain,
            details: { chainId: sellAsset.chainId },
        }));
    }
    if (sellAssetChainId !== buyAssetChainId) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: `cross-chain not supported - both assets must be on chainId ${sellAsset.chainId}`,
            code: types_1.TradeQuoteError.CrossChainNotSupported,
            details: { buyAsset, sellAsset },
        }));
    }
    // Not a decimal percentage, just a good ol' percentage e.g 1 for 1%
    const affiliateBpsPercentage = (0, utils_1.convertBasisPointsToDecimalPercentage)(affiliateBps)
        .times(100)
        .toNumber();
    const userSlippageTolerancePercentageDecimalOrDefault = input.slippageTolerancePercentageDecimal
        ? Number(input.slippageTolerancePercentageDecimal) * 100
        : undefined; // Use auto slippage if no user preference is provided
    try {
        if (!sendAddress)
            return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({ message: 'missing sendAddress' }));
        const portalsNetwork = constants_1.chainIdToPortalsNetwork[chainId];
        if (!portalsNetwork) {
            return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
                message: `unsupported ChainId`,
                code: types_1.TradeQuoteError.UnsupportedChain,
                details: { chainId: input.chainId },
            }));
        }
        const sellAssetAddress = (0, helpers_1.isNativeEvmAsset)(sellAsset.assetId)
            ? viem_1.zeroAddress
            : (0, caip_1.fromAssetId)(sellAsset.assetId).assetReference;
        const buyAssetAddress = (0, helpers_1.isNativeEvmAsset)(buyAsset.assetId)
            ? viem_1.zeroAddress
            : (0, caip_1.fromAssetId)(buyAsset.assetId).assetReference;
        const inputToken = `${portalsNetwork}:${sellAssetAddress}`;
        const outputToken = `${portalsNetwork}:${buyAssetAddress}`;
        // Attempt fetching a quote with validation enabled to leverage upstream gasLimit estimate
        const portalsTradeOrderResponse = await (0, fetchPortalsTradeOrder_1.fetchPortalsTradeOrder)({
            sender: sendAddress,
            inputToken,
            outputToken,
            inputAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
            slippageTolerancePercentage: userSlippageTolerancePercentageDecimalOrDefault,
            partner: (0, helpers_1.getTreasuryAddressFromChainId)(sellAsset.chainId),
            feePercentage: affiliateBpsPercentage,
            validate: true,
            swapperConfig,
        }).catch(async (e) => {
            // If validation fails, fire 3 more quotes:
            // 1. a quote estimate (does not require approval) to get the optimal slippage tolerance
            // 2. a quote with validation enabled, but using a well-funded address to get a rough gasLimit estimate
            // 3. another quote with validation disabled, to get an actual quote (using the user slippage, or the optimal from the estimate)
            console.info('failed to get Portals quote with validation enabled', e);
            // Use the quote estimate endpoint to get the optimal slippage tolerance
            const quoteEstimateResponse = await (0, fetchPortalsTradeOrder_1.fetchPortalsTradeEstimate)({
                sender: sendAddress,
                inputToken,
                outputToken,
                inputAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
                swapperConfig,
            }).catch(e => {
                console.info('failed to get Portals quote estimate', e);
                return undefined;
            });
            const dummyQuoteParams = (0, helpers_2.getDummyQuoteParams)(sellAsset.chainId);
            const dummySellAssetAddress = (0, caip_1.fromAssetId)(dummyQuoteParams.sellAssetId).assetReference;
            const dummyBuyAssetAddress = (0, caip_1.fromAssetId)(dummyQuoteParams.buyAssetId).assetReference;
            const dummyInputToken = `${portalsNetwork}:${dummySellAssetAddress}`;
            const dummyOutputToken = `${portalsNetwork}:${dummyBuyAssetAddress}`;
            // Use a dummy request to the portal endpoint to get a rough gasLimit estimate
            const dummyOrderResponse = await (0, fetchPortalsTradeOrder_1.fetchPortalsTradeOrder)({
                sender: dummyQuoteParams.accountAddress,
                inputToken: dummyInputToken,
                outputToken: dummyOutputToken,
                inputAmount: dummyQuoteParams.sellAmountCryptoBaseUnit,
                slippageTolerancePercentage: userSlippageTolerancePercentageDecimalOrDefault,
                partner: (0, helpers_1.getTreasuryAddressFromChainId)(sellAsset.chainId),
                feePercentage: affiliateBpsPercentage,
                validate: true,
                swapperConfig,
            })
                .then(({ context }) => ({
                maybeGasLimit: context.gasLimit,
            }))
                .catch(e => {
                console.info('failed to get Portals quote with validation enabled using dummy address', e);
                return undefined;
            });
            const order = await (0, fetchPortalsTradeOrder_1.fetchPortalsTradeOrder)({
                sender: sendAddress,
                inputToken,
                outputToken,
                inputAmount: sellAmountIncludingProtocolFeesCryptoBaseUnit,
                slippageTolerancePercentage: userSlippageTolerancePercentageDecimalOrDefault ??
                    quoteEstimateResponse?.context.slippageTolerancePercentage ??
                    (0, utils_1.bnOrZero)((0, __1.getDefaultSlippageDecimalPercentageForSwapper)(types_1.SwapperName.Portals))
                        .times(100)
                        .toNumber(),
                partner: (0, helpers_1.getTreasuryAddressFromChainId)(sellAsset.chainId),
                feePercentage: affiliateBpsPercentage,
                validate: false,
                swapperConfig,
            });
            if (dummyOrderResponse?.maybeGasLimit)
                order.context.gasLimit = dummyOrderResponse.maybeGasLimit;
            return order;
        });
        const { context: { orderId, outputAmount: buyAmountAfterFeesCryptoBaseUnit, minOutputAmount: buyAmountBeforeFeesCryptoBaseUnit, slippageTolerancePercentage, target: allowanceContract, feeAmount, gasLimit, }, } = portalsTradeOrderResponse;
        const rate = (0, utils_2.getRate)({
            sellAmountCryptoBaseUnit: input.sellAmountIncludingProtocolFeesCryptoBaseUnit,
            buyAmountCryptoBaseUnit: buyAmountAfterFeesCryptoBaseUnit,
            sellAsset,
            buyAsset,
        });
        const adapter = assertGetEvmChainAdapter(chainId);
        const { average } = await adapter.getGasFeeData();
        const networkFeeCryptoBaseUnit = chain_adapters_1.evm.calcNetworkFeeCryptoBaseUnit({
            ...average,
            supportsEIP1559,
            // times 1 isn't a mistake, it's just so we can write this comment above to mention that Portals already add a
            // buffer of ~15% to the gas limit
            gasLimit: (0, utils_1.bnOrZero)(gasLimit).times(1).toFixed(),
        });
        const slippageTolerancePercentageDecimal = (0, utils_1.bnOrZero)(slippageTolerancePercentage)
            .div(100)
            .toString();
        const tradeQuote = {
            id: orderId,
            receiveAddress: input.receiveAddress,
            affiliateBps,
            potentialAffiliateBps,
            rate,
            slippageTolerancePercentageDecimal,
            steps: [
                {
                    accountNumber,
                    allowanceContract,
                    rate,
                    buyAsset,
                    sellAsset,
                    buyAmountBeforeFeesCryptoBaseUnit,
                    buyAmountAfterFeesCryptoBaseUnit,
                    sellAmountIncludingProtocolFeesCryptoBaseUnit: input.sellAmountIncludingProtocolFeesCryptoBaseUnit,
                    feeData: {
                        networkFeeCryptoBaseUnit,
                        // Protocol fees are always denominated in sell asset here
                        protocolFees: {
                            [sellAsset.assetId]: {
                                amountCryptoBaseUnit: feeAmount,
                                asset: sellAsset,
                                requiresBalance: false,
                            },
                        },
                    },
                    source: types_1.SwapperName.Portals,
                    estimatedExecutionTimeMs: undefined, // Portals doesn't provide this info
                },
            ],
        };
        return (0, monads_1.Ok)(tradeQuote);
    }
    catch (err) {
        return (0, monads_1.Err)((0, utils_2.makeSwapErrorRight)({
            message: 'failed to get Portals quote',
            cause: err,
            code: types_1.TradeQuoteError.NetworkFeeEstimationFailed,
        }));
    }
}
exports.getPortalsTradeQuote = getPortalsTradeQuote;
